@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .animate-fade-in {
    animation: fade-in 0.3s ease-out;
  }

 .carousel .control-next.control-arrow:before {
    content: url('/src/assets/images/right-arrow-1.svg') !important; 
    border: 1px solid transparent !important; 
}

.carousel .control-prev.control-arrow:before {
   content: url('/src/assets/images/left-arrow-1.svg') !important; 
   border: 1px solid transparent !important; 
} 

.my-masonry-grid {
  display: -webkit-box; 
  display: -ms-flexbox; 
  display: flex;
  margin-left: -30px;
  width: 100%;
}
.my-masonry-grid_column {
  padding-left: 30px; 
  background-clip: padding-box;
}

.my-masonry-grid_column > div { 
  /* background: #D9D9D9; */
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .my-masonry-grid {
    margin-left: 0px;
  }
  .my-masonry-grid_column {
    padding-left: 0px;
  }
}

  /* CSS for switch*/
	.switch {
	  position: relative;
	  display: inline-block;
	  width: 100%;
/* 	  height: 34px; */
	}
	/* Hiding the default checkbox*/
	.switch input {
	  opacity: 0;
	  width: 0;
	  height: 0;
	}
	/* The slider */
	.slider {
	  position: absolute;
	  cursor: pointer;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	  /* background-color: #cccccc; */
	  -webkit-transition: .3s;
	  transition: .3s;
	}
    /* Before sliding */
	.slider:before {
	  position: absolute;
	  content: "";
	  height: 100%;
	  width: 50%;
	  left: 0px;
	  bottom: 0px;
	  background-color: white;
      border-radius: 51px;
	  -webkit-transition: .3s;
	  transition: .3s;
	}
	input:checked + .slider {
	  /* background-color: #234567; */
	}
/* 	input:focus + .slider {
	  box-shadow: 0 0 1px #564311;
	} */
	input:checked + .slider:before {
	  -webkit-transform: translateX(100%);
	  -ms-transform: translateX(100%);
	  transform: translateX(100%);
	}

    .switch-label {
      transition: color 0.3s ease; 
    }

    .switch-label.active {
      color: black; 
    }

    .switch-label.inactive {
      color: white; 
    }
	.text-gradient-custom {
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}

	.custom_phone_btn > .selected-flag {
		background-color: unset !important;
	}

	.shiny_custom {
		inset: 50% -50% -50% 50%;
		animation: shiny_rotate 3s infinite linear; 
		transform-origin: 0 0;
		filter: blur(5px);
	}

	@keyframes shiny_rotate {
		0% {
			transform: rotate(0deg);
		}
		33% {
			transform: rotate(120deg);
		}
		66% {
			transform: rotate(210deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}


.custom-scroll::-webkit-scrollbar {
	background-color: #2440D6;
	width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.custom-scroll-sm::-webkit-scrollbar {
	background-color: #2440D6;
	width: 3px;
}

.custom-scroll-sm::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.custom-scroll-sm-white::-webkit-scrollbar {
	background-color: #fff;
	width: 3px;
}

.custom-scroll-sm-white::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #6E7585;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.top_shadow {
	-webkit-box-shadow: 0px -13px 21px -9px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px -13px 21px -9px rgba(0,0,0,0.57);
	box-shadow: 0px -13px 21px -9px rgba(0,0,0,0.57);
}

/* CSS for switch-base*/
.switch-base {
	position: relative;
	display: inline-block;
	width: 100%;
/* 	  height: 34px; */
  }
  /* Hiding the default checkbox*/
  .switch-base input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  /* The slider */
  .slider-base {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
    background-color: #cccccc; 
	border-radius: 36px;
	-webkit-transition: .3s;
	transition: .3s;
  }
  /* Before sliding */
  .slider-base:before {
	position: absolute;
	content: "";
	height: 23px;
	width: 23px;
	left: 1px;
	bottom: 1px;
	background-color: white;
	border-radius: 36px;
	-webkit-transition: .3s;
	transition: .3s;
  }

  input:checked + .slider-base:before {
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
  }

  input:checked + .slider-base {
	 background-color: #2440D6; 
  }

  .switch-label {
	transition: color 0.3s ease; 
  }

  .triangle_right {
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 15px 0 15px 30px;
	border-color: transparent transparent transparent #fff;
  }
	


